import { Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useNavigate, useLocation } from "react-router-dom";
import Logo from "./Logo";
import { useAuth } from "../Components/AuthContext.js";
import { doSignOut } from "../firebase/auth";
import { useState } from "react";
//turn eslint off

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const cartIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke-width="1.5"
    stroke="currentColor"
    class="w-4 h-4"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M15.75 10.5V6a3.75 3.75 0 1 0-7.5 0v4.5m11.356-1.993 1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 0 1-1.12-1.243l1.264-12A1.125 1.125 0 0 1 5.513 7.5h12.974c.576 0 1.059.435 1.119 1.007ZM8.625 10.5a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm7.5 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
    />
  </svg>
);

const magicIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke-width="1.5"
    stroke="currentColor"
    class="w-4 h-4"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z"
    />
  </svg>
);

export default function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();

  const { userLoggedIn } = useAuth();
  const { user, currentUser } = useAuth();

  const [toggleMenu, setToggleMenu] = useState(false);

  const handleLogout = () => {
    setToggleMenu(!toggleMenu);
    doSignOut();
    navigate("/");
  };

  function getDisplayName(currentUser) {
    if (!currentUser || !currentUser.displayName) {
      return "Profile";
    }
    const names = currentUser.displayName.split(" ");
    return names.length > 0 ? names[0] : currentUser.displayName;
  }

  return (
    <Disclosure as="nav" className="">
      {({ open }) => (
        <>
          <div className="mx-auto  px-4 sm:px-6 lg:px-8">
            <div className="flex h-16 justify-between relative">
              <div className="flex">
                <div
                  className="flex flex-shrink-0 items-center hover:cursor-pointer"
                  onClick={() => navigate("/")}
                >
                  <Logo />
                </div>
                <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                  {/* Current: "border-blue-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                  <a
                    href={userLoggedIn ? "/home" : "/"}
                    className={`inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium ${
                      location.pathname === "/home" ||
                      (location.pathname === "/" && !userLoggedIn)
                        ? "border-blue-500 text-gray-900"
                        : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                    }`}
                  >
                    {userLoggedIn ? "Dashboard" : "Home"}
                  </a>
                  <a
                    href="/#card"
                    className={`inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium ${
                      location.pathname === "/#card" ||
                      (location.pathname === "/" && userLoggedIn)
                        ? "border-blue-500 text-gray-900"
                        : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                    }`}
                  >
                    Card
                  </a>
                  <a
                    href="/Lens"
                    className={`inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium ${
                      location.pathname === "/Lens"
                        ? "border-blue-500 text-gray-900"
                        : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                    }`}
                  >
                    Lens
                  </a>
                </div>
              </div>
              <div className="hidden sm:ml-6 sm:flex sm:items-center  space-x-8">
                {!userLoggedIn && (
                  <button
                    className="flex flex-row justify-center items-center gap-2 text-xs bg-blue-200 font-medium px-4 py-2 text-blue-800 rounded outline-blue-200 hover:bg-blue-500 transition-all hover:text-white"
                    onClick={() =>
                      (window.location.href =
                        "https://www.amazon.com/dp/B0DCLPXDDY")
                    }
                  >
                    <div className="duration-500">{cartIcon}</div>
                    <div className="duration-100">Buy card</div>
                  </button>
                )}

                <div className="flex bg-white p-4 z-10 items-center w-24">
                  <div className="flex gap-2 flex-col items-end absolute right-0 top-0 p-4 z-10 ">
                    <div
                      className="flex gap-4 cursor-pointer p-1 pl-4  bg-blue-50 text-blue-800 text-xs font-medium  outline-1 outline-blue-900 group  rounded-md hover:rounded-lg  items-center hover:bg-blue-100 transition-all"
                      onClick={
                        currentUser
                          ? () => setToggleMenu(!toggleMenu)
                          : () => navigate("/login")
                      }
                    >
                      {currentUser ? getDisplayName(currentUser) : "Login"}
                      <div className="flex items-center justify-center h-6 w-6 rounded-md group-hover:rounded-lg bg-blue-800 text-blue-50 transition-all"></div>
                    </div>
                    {currentUser && toggleMenu ? (
                      <div
                        className="text-xs p-2 px-4 cursor-pointer bg-opacity-50 rounded-md hover:rounded-lg bg-blue-50 text-blue-800 font-medium hover:bg-blue-100 transition-all"
                        onClick={() => handleLogout()}
                      >
                        Logout
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                {/* Profile dropdown */}
              </div>
              {
                <div className="-mr-2 flex items-center sm:hidden">
                  {/* Mobile menu button */}
                  <>
                    {/* {!userLoggedIn && (
                      <button
                        className=" flex flex-row justify-center items-center gap-2 text-xs bg-blue-200 font-medium px-4 mr-2 py-2 text-blue-800 rounded outline-blue-200 hover:bg-blue-500 transition-all hover:text-white"
                        onClick={() =>
                          (window.location.href =
                            "https://www.amazon.com/dp/B0DCLPXDDY")
                        }
                      >
                        <div className=" ">{cartIcon}</div>
                        <div>Buy card</div>
                      </button>
                    )} */}

                    <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
                      <span className="absolute -inset-0.5" />
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XMarkIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      ) : (
                        <Bars3Icon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                  </>
                </div>
              }
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 pb-3 pt-2">
              {/* Current: "bg-blue-50 border-blue-500 text-blue-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
              <Disclosure.Button
                as="a"
                href={userLoggedIn ? "/home" : "/"}
                className={`block  py-2 pl-3 pr-4 text-base font-medium border-l-4   ${
                  location.pathname === "/home" ||
                  (location.pathname === "/" && !userLoggedIn)
                    ? "border-l-4 border-blue-500 bg-blue-50 text-blue-700"
                    : "border-transparent text-gray-500"
                }`}
              >
                {userLoggedIn ? "Dashboard" : "Home"}
              </Disclosure.Button>
              <Disclosure.Button
                as="a"
                href={userLoggedIn ? "/#card" : "/#card"}
                className={`block  py-2 pl-3 pr-4 text-base font-medium border-l-4   ${
                  location.pathname === "/#card" ||
                  (location.pathname === "/" && userLoggedIn)
                    ? "border-l-4 border-blue-500 bg-blue-50 text-blue-700"
                    : "border-transparent text-gray-500"
                }`}
              >
                Card
              </Disclosure.Button>
              <Disclosure.Button
                as="a"
                href="/Lens"
                className={`block  py-2 pl-3 pr-4 text-base font-medium border-l-4   ${
                  location.pathname === "/Lens"
                    ? "border-l-4 border-blue-500 bg-blue-50 text-blue-700"
                    : "border-transparent text-gray-500"
                }`}
              >
                Lens
              </Disclosure.Button>
              <Disclosure.Button
                as="a"
                href="https://www.amazon.com/dp/B0DCLPXDDY"
                target="_blank"
                className={`block  py-2 pl-3 pr-4 text-base font-medium border-l-4   ${
                  location.pathname === "/Shop"
                    ? "border-l-4 border-blue-500 bg-blue-50 text-blue-700"
                    : "border-transparent text-gray-500"
                }`}
              >
                Buy card
              </Disclosure.Button>
              <Disclosure.Button
                onClick={
                  userLoggedIn ? () => handleLogout() : () => navigate("/login")
                }
                className={`block  py-2 pl-3 pr-4 text-base font-medium border-l-4 bg-blue-600   ${
                  location.pathname === "/login"
                    ? "border-l-4 border-blue-500 bg-blue-50 text-blue-50"
                    : "border-transparent text-blue-50 hover:bg-blue-700 hover:text-white"
                }`}
              >
                {userLoggedIn ? "Logout" : "Login"}
              </Disclosure.Button>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
