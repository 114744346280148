import React from "react";
import { useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    const currentPath = location.pathname; // Get the current path

    // Check if the current path contains a Google Docs link
    const isGoogleDocLink = currentPath.includes("/docs.google.com/document/d");

    if (isGoogleDocLink) {
      // If it's a Google Docs link, navigate to the appropriate route
      navigate(`/resume${currentPath}`);
    }
  }, []);

  return (
    <div className="flex flex-col justify-center items-center h-full">
      <h1 className="font-bold text-4xl">404</h1>
      <p>Page not found</p>
    </div>
  );
};

export default NotFound;
