import { Disclosure } from "@headlessui/react";
import { MinusSmallIcon, PlusSmallIcon } from "@heroicons/react/24/outline";
import FAQ from "../../Components/FAQ";

const faqs = [
  {
    question: "How do I use the 'Tailor My Resume' tool?",
    answer:
      "To use the tool, make sure your resume is a Google Doc and shared with anyone who has the link. Then, paste the link and the job description here, and the tool will automatically suggest improvements to better align your resume with the job.",
  },
  {
    question: "My Google Doc link isn’t working. What should I do?",
    answer:
      "Ensure that the document is shared properly by selecting 'Anyone with the link can edit.' Also, double-check that it is indeed a Google Doc. If it has a .docx extension (visible at the top left after the file name), it’s a Word document. To fix this, go to File > Save as Google Doc.",
  },
  {
    question: "Do I need to worry about privacy?",
    answer:
      "No, your privacy is important to us. We don’t store or access your files beyond making the tailored resume suggestions. Once the tailoring process is done, the information is discarded.",
  },
  {
    question: "Will this tool increase my chances of getting a job?",
    answer:
      "Our tool helps align your resume with job descriptions by focusing on important keywords and phrasing. While it can improve your resume's relevance to specific job postings, we can't guarantee job offers, but it increases the likelihood of being noticed.",
  },
  {
    question: "What happens to my document after I paste the link?",
    answer:
      "The tool reads your resume to make suggestions based on the job description you provide. Afterward, the suggestions are given to you for review. You remain in full control of your document.",
  },
  {
    question: "Why is my Google Doc not working?",
    answer:
      "Ensure the document is set to 'Anyone with the link can edit.' If the issue persists, check that the document is a Google Doc and not a .docx file. You can convert it by selecting File > Save as Google Doc.",
  },
  {
    question: "Can I tailor my resume for multiple job descriptions?",
    answer:
      "Absolutely! You can paste new job descriptions and adjust your resume multiple times. Each tailored version will provide suggestions specific to the new job.",
  },
  {
    question: "Does the tool automatically update my resume?",
    answer:
      "The tool provides suggestions based on the job description. You’ll have the option to review and apply these suggestions yourself, keeping you in full control of your document.",
  },
  {
    question:
      "Do I have to use Google Docs, or can I use other formats like Word?",
    answer:
      "Currently, the tool works best with Google Docs. If you're using another format like Word, you’ll need to convert it to Google Docs by selecting File > Save as Google Doc in Google Drive.",
  },
  {
    question: "How long does it take to get resume suggestions?",
    answer:
      "The process is quick. Once you've shared the document and pasted the job description, suggestions should be generated within a few seconds.",
  },
];

export default function ResumeFaq() {
  return (
    <div className="h-full w-full">
      <FAQ faqs={faqs} />
    </div>
  );
}
