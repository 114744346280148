import React, { useState } from "react";
import { XCircleIcon } from "@heroicons/react/24/outline";

const ResumeOnboarding = ({ setOnboarding, tailorResume }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const steps = [
    {
      step: 1,
      title: (
        <>
          Make sure your doc does not have
          <span className="bg-blue-600 text-white rounded-sm px-1 py-0.5 text-xs mx-1">
            .DOCX
          </span>
          or any extension
        </>
      ),
      important: true,
      tutorial: require("../../Assets/resumeTailor/remove_docx.gif"),
      description: (
        <>
          To verify, check the file extension at the top-left corner, next to
          the document name. If it says '.docx' or anything else, click{" "}
          <span className="font-semibold">
            'File' &gt; 'Save as Google Doc'.
          </span>
        </>
      ),
    },
    {
      step: 2,
      title: "Ensure the link allows editing",
      tutorial: require("../../Assets/resumeTailor/share.gif"),
      description: (
        <>
          The document should be shared with{" "}
          <span className="font-semibold">
            'Anyone with the link <span className="underline">can edit</span>'
          </span>{" "}
          so our tool can access and tailor it.
        </>
      ),
    },
    {
      step: 4,
      title: "If you want to revert or undo changes",
      tutorial: require("../../Assets/resumeTailor/version_history.gif"),
      description: (
        <>
          Go to{" "}
          <span className="font-semibold">'File' &gt; 'Version history' </span>
          in Google Docs to see and restore any previous versions of your
          document.,
        </>
      ),
    },
  ];

  const handleNext = (step) => {
    const nextIndex = currentIndex + step;
    if (nextIndex < 0) {
      return;
    }
    if (nextIndex === steps.length) {
      setOnboarding(false);
      //create dummy event
      const event = new Event("onboardingComplete");
      tailorResume(event);

      return;
    }
    setCurrentIndex(currentIndex + step);
  };

  //listen for Esc press
  document.addEventListener(
    "keydown",
    (e) => {
      if (e.key === "Escape") {
        setOnboarding(false);
      }
    },
    false
  );

  return (
    <div className="absolute flex flex-grow top-8 min-h-[90vh] px-4 py-32 backdrop-filter backdrop-brightness-50 bg-opacity-40 backdrop-blur-md z-20 flex-col items-center justify-center  w-full">
      <div className="flex relative flex-col justify-between flex-grow min-h-full w-full md:w-[800px] md:mh-[500px] bg-stone-50 border-2 border-white rounded-xl items-center p-8">
        <XCircleIcon
          className="absolute top-6 left-6 h-6 text-gray-400 hover:text-gray-800 cursor-pointer transition-all duration-100"
          onClick={() => setOnboarding(false)}
        />
        <div className="pb-2 text-xl font-bold">Before we get started..</div>
        <div className="flex flex-col gap-4 items-start justify-center md:w-2/3">
          {steps[currentIndex].important && (
            <div className="rounded-full font-medium self-start justify-self-start flex-grow-0 py-1 px-4 border border-red-500 bg-red-50 text-red-500 text-[10px]">
              Important
            </div>
          )}
          <div className="text-lg">{steps[currentIndex].title}</div>
          {steps[currentIndex].tutorial && (
            <img
              src={steps[currentIndex].tutorial}
              alt="tutorial"
              className="rounded-lg h-40 absolute:cover"
            />
          )}
          <div className="text-sm font-light pb-8">
            {steps[currentIndex].description}
          </div>
        </div>

        <div className="w-full items-start justify-start">
          <div className="flex flex-row gap-4 w-full py-8">
            <button
              className="px-4 py-4 bg-gray-100 w-full disabled:opacity-50 disabled:text-gray-400 disabled:hover:bg-gray-100  text-black rounded-lg hover:bg-gray-200"
              onClick={() => {
                handleNext(-1);
              }}
              disabled={currentIndex === 0}
            >
              Back
            </button>
            <button
              className={`px-4 py-4 bg-sky-700 border hover:bg-sky-600 w-full text-white rounded-lg `}
              onClick={(e) => {
                handleNext(1);
                e.preventDefault();
              }}
            >
              {currentIndex === steps.length - 1 ? "Got it" : "Next"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResumeOnboarding;
