'use client'

import { useState } from 'react'
import Loader from '../Components/Loader'
import axios from 'axios'
import Alert from '../Components/Alert';
import NewlineText from '../Components/NewlineText';
import { useAuth } from "../Components/AuthContext.js";

export default function LinkedInRoaster() {
  const [linkedinUrl, setLinkedinUrl] = useState("")
  const [email, setEmail] = useState("")
  const [loading, setLoading] = useState(false)
  const [loadingEmail, setLoadingEmail] = useState(false)
  const [roast, setRoast] = useState("")
  const [alert, setAlert] = useState("");
  const [alertType, setAlertType] = useState("");
  const [userAddedEmail, setUserAddedEmail] = useState(false);

  const { userLoggedIn } = useAuth();

  const BASE_URL = process.env.REACT_APP_PUBLIC_BACKEND_URL;

  const submitEmail = async(e) => {
    e.preventDefault();
    if (email.length === 0) {
      setAlertType("error");
      setAlert("Please enter an email address.");
      return;
    }

    setLoadingEmail(true);

    try {
      const body = {
        source: "LinkedInRoaster",
        email: email
      }
      await axios.post(`${BASE_URL}save_lead`, body);
      setAlertType("success");
      setAlert("Success!");
    } catch (error) {
      console.error(error);
      setAlertType("error");
      setAlert("An error occurred. Please try again.");
    }
    setLoadingEmail(false);
    setUserAddedEmail(true);
  }

  const roastProfile = async(e) => {
    e.preventDefault();

    if (linkedinUrl.length === 0) {
      setAlertType("error");
      setAlert("Please enter a LinkedIn URL.");
      return;
    }

    const urlEncoded = encodeURIComponent(linkedinUrl);

    setLoading(true);
    try {
      const res = await axios.get(`${BASE_URL}roast_linkedin?linkedin_profile_url=${urlEncoded}`);
      const newRoast = res.data.roast + "\n\n\n" + "Psst... are you a student looking for a job? Try out a digital business card to stand out to recruiters! https://penpal.cards";
      setRoast(newRoast);
    } catch (error) {
      console.error(error);
      setAlertType("error");
      setAlert("An error occurred roasting your LinkedIn. Please try again.");
    }
    setLoading(false);
  }

  return (
    <div className="relative isolate overflow-hidden bg-gradient-to-b from-indigo-100/20 pt-14">
      <div
        aria-hidden="true"
        className="absolute inset-y-0 right-1/2 -z-10 -mr-96 w-[200%] origin-top-right skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 sm:-mr-80 lg:-mr-96"
      />
      <div className="mx-auto max-w-7xl px-6 py-32 sm:py-40 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none lg:gap-x-16 lg:gap-y-6 xl:grid-cols-1 xl:grid-rows-1 xl:gap-x-8">
          <h1 className="max-w-2xl text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl lg:col-span-2 xl:col-auto mb-4">
            Roast your LinkedIn
          </h1>
          {(userLoggedIn || userAddedEmail) && (
            <div className="mt-6 max-w-xl lg:mt-0 xl:col-end-1 xl:row-start-1">
              <p className="text-lg leading-8 text-gray-600">
                Ready for some harsh feedback? Get your LinkedIn roasted for free by our AI.
              </p>
              <div className="mt-10 flex items-center gap-x-6">
                <input
                  type="text"
                  placeholder="Enter your LinkedIn URL"
                  className="block w-full px-4 py-2.5 text-base text-gray-900 placeholder-gray-500 bg-white border border-gray-300 rounded-lg shadow-sm focus:ring-sky-800 focus:border-sky-800 sm:text-lg"
                  value={linkedinUrl}
                  onChange={e => setLinkedinUrl(e.target.value)}
                />
              </div>
              <button
                type="button"
                className="inline-flex items-center justify-center px-6 py-2 my-4 text-lg font-semibold text-white bg-sky-600 rounded-lg shadow-sm hover:bg-sky-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-800"
                onClick={roastProfile}
              >
                Roast
              </button>
              {alert && (
                <div className="my-4 w-full sm:flex sm:justify-center">
                    <Alert type={alertType} messageBody="" messageHeader={alert} changeMessage={setAlert}/>
                </div>
              )}
            </div>
          )}

          {loading && (
            <div className="mt-6 max-w-xl lg:mt-0 xl:row-start-1">
              <Loader height={70} width={70} />
            </div>
          )}
          {(roast && !loading) && (
            <div className="mt-12 max-w-xl lg:mt-0 xl:col-end-1 xl:row-start-1">
              <NewlineText text={roast} truncate={false} />
            </div>
          )}

          {(!userLoggedIn && !userAddedEmail) && (
            <div className="mt-16 max-w-xl xl:col-end-1 xl:row-start-1">
              <p className="text-lg leading-8 text-gray-600">
                To get your roast, please <a href='/login' className="text-sky-600 hover:text-sky-800">log in</a> or enter your email address below.
              </p>
              <div className="mt-2 flex items-center gap-x-6">
                <input
                  type="text"
                  placeholder="Email"
                  className="block w-full px-4 py-2.5 text-base text-gray-900 placeholder-gray-500 bg-white border border-gray-300 rounded-lg shadow-sm focus:ring-sky-800 focus:border-sky-800 sm:text-lg"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
              
                <button
                  type="button"
                  className="inline-flex items-center justify-center px-6 py-2 my-4 text-lg font-semibold text-white bg-sky-600 rounded-lg shadow-sm hover:bg-sky-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-800"
                  onClick={submitEmail}
                >
                  Submit
                </button>
                </div>
            </div>
          )}
          {loadingEmail && (
            <div className="mt-6 max-w-xl lg:mt-0 xl:row-start-1">
              <Loader height={70} width={70} />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
