import { useState } from 'react'
export default function WriteFeedback({ createFeedback, user }) {
  const [description, setDescription] = useState("")

  return (      
    <form className="relative w-full">
        <span className="font-bold">Have feedback or a feature suggestion?</span><br/>
        <span className="">Let us and the community know about it here!</span>
      <div className="overflow-hidden rounded-lg border border-gray-300 shadow-sm focus-within:border-primary focus-within:ring-1 focus-within:ring-primary">
        <label htmlFor="description" className="sr-only">
          Feedback, suggestion, concern, or idea
        </label>
        <textarea
          rows={4}
          name="description"
          id="description"
          value={description}
          maxLength={400}
          onChange={e => setDescription(e.target.value)}
          className="block w-full resize-none border-0 py-0 text-black placeholder:text-gray-600 focus:ring-0 px-2 sm:text-md sm:leading-6 bg-primaryExtraLight"
          placeholder="Feedback, suggestion, concern, or idea (max 1000 chars)"
        />

        {/* Spacer element to match the height of the toolbar */}
        <div aria-hidden="true">
          <div className="py-2">
            <div className="h-4" />
          </div>
          <div className="h-px" />
          <div className="py-2">
            <div className="py-px">
              <div className="h-4" />
            </div>
          </div>
        </div>
      </div>
      <button
        className="absolute w-full bottom-0 right-0 sm:w-auto sm:bottom-3 sm:right-3 bg-sky-600 hover:bg-sky-800 text-white font-bold py-2 px-4 rounded"
        onClick={e => {
          e.preventDefault();
          createFeedback(description, user);
          setDescription("");
        }}
      >
        <span>Submit anonymously</span>
      </button>
    </form>
  )
}
