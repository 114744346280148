import React, { useEffect, useState, useRef } from "react";
import { motion } from "framer-motion";
import Typewriter from "typewriter-effect";

const Welcome = ({ setWelcome }) => {
  const videoRef = useRef(null);
  const [videoEnded, setVideoEnded] = useState(false);
  const [autoplaySupported, setAutoplaySupported] = useState(true);

  // Detect if autoplay is supported
  useEffect(() => {
    const testAutoplay = async () => {
      const video = document.createElement("video");
      video.muted = true;
      video.playsInline = true;
      video.src = require("../../Assets/handlers/handler_animation.mp4"); // Test video
      try {
        await video.play();
        setAutoplaySupported(true); // Autoplay works
      } catch {
        setAutoplaySupported(false); // Autoplay is blocked
      }
    };
    testAutoplay();
  }, []);

  // Pause or play video based on visibility
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden && videoRef.current) {
        videoRef.current.pause();
      } else if (videoRef.current) {
        videoRef.current.play();
      }
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () =>
      document.removeEventListener("visibilitychange", handleVisibilityChange);
  }, []);

  // Reduce playback speed
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = 0.75; // Set playback speed
    }
  }, [autoplaySupported]);

  // Handle video end event
  const handleVideoEnd = () => {
    setVideoEnded(true);
  };

  useEffect(() => {
    if (videoEnded) {
      console.log("You are welcome");
    }
  }, [videoEnded]);

  return (
    <div className="h-screen w-full bg-black relative overflow-hidden">
      <>
        {/* Background Video */}
        <motion.video
          ref={videoRef}
          id="handler_animation"
          className="absolute inset-0 w-full h-full object-cover z-0"
          autoPlay
          preload="auto"
          playsinline
          muted
          playsInline
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1.5, ease: "easeOut" }}
          onEnded={handleVideoEnd}
          src={require("../../Assets/handlers/handler_animation.mp4")}
        ></motion.video>
        {videoEnded && (
          <motion.div
            className="absolute inset-0 bg-black"
            id="bg"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
          />
        )}

        {/* Optional Overlay for Darkening */}
      </>

      {/* Typewriter Animation */}
      {videoEnded && (
        <div className="absolute inset-0  flex flex-col items-center justify-center ">
          <motion.div
            className="flex flex-col justify-center items-center w-full gap-60 z-10 text-white font-extralight"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, ease: "easeOut" }}
          >
            <Typewriter
              onInit={(typewriter) => {
                typewriter
                  .typeString("Welcome, Handler")
                  .pauseFor(1000)
                  .deleteAll()
                  .typeString(
                    "The University of Rochester is proud to have you"
                  )
                  .pauseFor(1500)
                  .deleteAll()
                  .typeString("You represent the top 1% of students here")
                  .pauseFor(1500)
                  .deleteAll()
                  .typeString(
                    "This card will allow you to stand out while networking"
                  )
                  .pauseFor(1500)
                  .deleteAll()
                  .typeString("Let’s get you started..")
                  .start();
              }}
              options={{
                cursor: "|",
                delay: 50,
                deleteSpeed: 95,
                typeSpeed: 95,
              }}
            />
            <motion.button
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 5, duration: 1, ease: "easeIn" }}
              className=" "
            >
              <a
                onClick={() => setWelcome(false)}
                className="bg-blue-500 px-4 z-20 py-2 text-white rounded-md"
              >
                Continue
              </a>
            </motion.button>
          </motion.div>
        </div>
      )}
    </div>
  );
};

export default Welcome;
