import React from "react";
import { useState, useEffect } from "react";
import { useAuth } from "../AuthContext";

const GreetingComponent = ({ currentUser }) => {
  const [greeting, setGreeting] = useState("");

  function getDisplayName(currentUser) {
    if (!currentUser || !currentUser.displayName) {
      return "";
    }
    const names = currentUser.displayName.split(" ");
    return names.length > 0 ? names[0] : currentUser.displayName;
  }

  useEffect(() => {
    const currentHour = new Date().getHours();

    if (currentHour < 12) {
      setGreeting("morning");
    } else if (currentHour >= 12 && currentHour < 18) {
      setGreeting("afternoon");
    } else {
      setGreeting("evening");
    }
  }, []); // Runs once on component mount

  return (
    <div className="text-2xl font-bold  p-2 mx-4 text-black justify-center  rounded-lg mb-4">
      Good {greeting}
      {currentUser && `, ${getDisplayName(currentUser)}`}
    </div>
  );
};
export default GreetingComponent;
