import React, { useEffect } from "react";
import { ReactComponent as LinkedInIcon } from "../../Assets/img/icons/icons-01.svg";
import { ReactComponent as PhoneIcon } from "../../Assets/img/icons/icons-02.svg";
import { ReactComponent as EmailIcon } from "../../Assets/img/icons/icons-03.svg";
import { ReactComponent as InstagramIcon } from "../../Assets/img/icons/icons-04.svg";
import { ReactComponent as XIcon } from "../../Assets/img/icons/icons-05.svg";
import { ReactComponent as GithubIcon } from "../../Assets/img/icons/icons-06.svg";
import { ReactComponent as WhatsappIcon } from "../../Assets/img/icons/icons-07.svg";
import { ReactComponent as TiktokIcon } from "../../Assets/img/icons/icons-08.svg";
import { ReactComponent as FacebookIcon } from "../../Assets/img/icons/icons-09.svg";
import { ReactComponent as SnapchatIcon } from "../../Assets/img/icons/icons-10.svg";
import { ReactComponent as WebsiteIcon } from "../../Assets/img/icons/icons-11.svg";
import { ReactComponent as Arrow } from "../../Assets/img/icons/arrow.svg";
//require image

const NewProfileCard = ({ person }) => {
  const getImageType = (imageUrl) => {
    const extension = imageUrl.split(".").pop().toLowerCase();
    switch (extension) {
      case "jpg":
      case "jpeg":
        return "JPEG";
      case "png":
        return "PNG";
      case "gif":
        return "GIF";
      default:
        return "JPEG"; // Default to JPEG if you cannot determine
    }
  };

  function downloadVCard(person) {
    const firstName = person.firstName || "";
    const lastName = person.lastName || "";
    const fullName = person.firstName + person.lastName || "";
    const telephone = person.phone || "";
    const email = person.email || "";
    const linkedIn = person.linkedIn || "";
    const x = person.x || "";
    const github = person.github || "";
    const instagram = person.instagram || "";
    const portfolio = person.portfolio || "";
    const imageUrl = person.imageUrl || "";
    const imageType = getImageType(imageUrl);
    const resume = person.resume || "";
    const headline = person.headline || "";

    const vCardData = [
      "BEGIN:VCARD",
      "VERSION:3.0",
      `N:${lastName};${firstName};;;`, // Surname; Given name; Additional names; Honorific prefixes; Honorific suffixes
      `FN:${fullName}`,
      `TITLE:${headline}`,
      `TEL;TYPE=CELL:${telephone}`,
      `EMAIL:${email}`,
      `URL:${portfolio}`,
      `X-SOCIALPROFILE;type=linkedIn;x-user=${linkedIn}`,
      `PHOTO;TYPE=${imageType};VALUE=URI:${imageUrl}`,
      "END:VCARD",
    ].join("\r\n");

    const blob = new Blob([vCardData], { type: "text/vcard" });
    const href = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = href;
    link.download = `${person.firstName}.vcf`; // Name of the file to download
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  }

  const copyToClipboard = async (textToCopy) => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      //alert to say success
      alert("Copied to clipboard");
    } catch (err) {
      alert(`Failed to copy. Try highlighting ${textToCopy} and select copy`);
    }
  };

  function formatPhoneNumber(phoneNumberString) {
    // Remove all non-numeric characters
    const cleaned = ("" + phoneNumberString).replace(/\D/g, "");

    // Check if the cleaned number has the correct length (10 digits)
    if (cleaned.length !== 10) {
      return phoneNumberString; // Return the original input if it's not a valid 10-digit number
    }

    // Format the number
    const formatted = `(${cleaned.slice(0, 3)}) ${cleaned.slice(
      3,
      6
    )}-${cleaned.slice(6)}`;

    return formatted;
  }

  function shortenString(str, length) {
    // Check if the string is longer than 15 characters
    if (str.length > length) {
      return str.slice(0, length) + "..."; // Return the first 15 characters with '...'
    }

    return str; // Return the
  }

  function ensureHttps(url) {
    // Check if the URL already starts with 'https://' or 'http://'
    if (!url.startsWith("https://") && !url.startsWith("http://")) {
      return `https://${url}`; // Prepend 'https://' if missing
    }
    return url; // Return the original URL if it already has the correct protocol
  }
  console.log(person);

  return (
    <div className="min-h-screen   w-full flex flex-col bg-black">
      <div className="h-32 w-full bg-sky-600 relative">
        <div className="absolute overflow-hidden h-32 w-full mix-blend-multiply">
          <img
            src={require("../../Assets/img/bg-banner.png")}
            alt="background-pattern"
            className="h-44 w-full object-cover"
          />
        </div>
      </div>

      <div className="w-full lg:w-2/3 lg:mx-auto p-8 -translate-y-24 flex flex-col items-start gap-8">
        <div className="w-full flex justify-between items-start">
          <div className="h-32 w-32 outline bg-black outline-2 relative overflow-hidden rounded-full">
            <img
              src={person.imageUrl}
              className="h-full w-full object-cover object-top"
            />
          </div>
          <div className="md:flex gap-2 grid grid-cols-1">
            {person.resume && (
              <a
                href={ensureHttps(person.resume)}
                target="_blank"
                className="bg-yellow-200 cursor-pointer text-black hover:bg-yellow-100 font-light rounded-lg  py-1 px-4 text-sm translate-y-20"
              >
                View <strong>resume</strong>
              </a>
            )}
            {/*Placeholder for second menue */}
          </div>
        </div>

        <div className="w-full flex flex-col items-start text-white gap-1">
          <div className="text-3xl">
            {person.firstName}{" "}
            <span className="font-bold">{person.lastName}</span>
          </div>
          {person.headline ? (
            <div className="pl-0.5">{person.headline}</div>
          ) : (
            <>
              <div className="">
                {person.position && person.position}{" "}
                {person.position && person.company && " @ "}
                {person.company && `${person.company}`}
              </div>
              <div className="">
                {person.major && person.major}
                {person.major && person.college && " @ "}
                {person.college && person.college}
              </div>
            </>
          )}
        </div>
        <div className="flex flex-row gap-4 justify-start  w-full font-extralight">
          <div
            className="py-4 md:px-10 px-4  flex flex-grow max-w-64 text-center items-center justify-center   bg-sky-600  cursor-pointer hover:bg-sky-500 text-white rounded-lg"
            onClick={() => downloadVCard(person)}
          >
            Save contact
          </div>
          {/* <div className="py-4 md:px-10 px-4 bg-stone-200 flex-grow max-w-64 text-black text-center items-center justify-center cursor-pointer hover:bg-stone-100 rounded-lg">
            Share <strong>your</strong> contact
          </div> */}
        </div>
        <div className="flex text-white text-sm flex-col gap-2 md:grid md:grid-cols-2 w-full -translate-x-2">
          {person.portfolio && (
            <a
              href={ensureHttps(person.portfolio)}
              target="_blank"
              className="flex gap-4 opacity-80 hover:opacity-100 hover:bg-stone-900 rounded-xl p-2 w-full"
            >
              <WebsiteIcon className="h-14" />
              <div className="flex  flex-col items-start">
                <div>Website</div>
                <div className="text-xs text-stone-200 font-light">
                  {shortenString(person.portfolio, 30)}
                </div>
              </div>
            </a>
          )}
          {person.linkedIn && (
            <a
              href={ensureHttps(person.linkedIn)}
              target="_blank"
              className="flex gap-4 opacity-80 hover:opacity-100 hover:bg-stone-900 rounded-xl p-2 w-full"
            >
              <LinkedInIcon className="h-14" />
              <div className="flex flex-col items-start">
                <div>LinkedIn</div>
                <div className="text-xs text-stone-200 font-light">
                  {shortenString(person.linkedIn, 40)}
                </div>
              </div>
            </a>
          )}
          {person.github && (
            <a
              href={ensureHttps(person.github)}
              target="_blank"
              className="flex gap-4 opacity-80 hover:opacity-100  hover:bg-stone-900 rounded-xl p-2 w-full"
            >
              <GithubIcon className="h-14" />
              <div className="flex flex-col items-start">
                <div>GitHub</div>
                <div className="text-xs text-stone-200 font-light">
                  {shortenString(person.github, 40)}
                </div>
              </div>
            </a>
          )}
          {person.email && (
            <a
              href={`mailto:${person.email}`}
              target="_blank"
              className="flex gap-4 opacity-80 hover:opacity-100 hover:bg-stone-900 rounded-xl p-2 w-full"
            >
              <EmailIcon className="h-14" />
              <div className="flex flex-col items-start">
                <div>Email</div>
                <div className="text-xs text-stone-200 font-light">
                  {shortenString(person.email, 50)}
                </div>
              </div>
            </a>
          )}
          {person.phone && (
            <div
              className="flex gap-4 opacity-80 hover:opacity-100 hover:cursor-pointer hover:bg-stone-900 rounded-xl p-2 w-full"
              onClick={() => copyToClipboard(person.phone)}
            >
              <PhoneIcon className="h-14" />
              <div className="flex flex-col items-start">
                <div>Phone</div>
                <div className="text-xs text-stone-200 font-light">
                  {formatPhoneNumber(person.phone)}
                </div>
              </div>
            </div>
          )}

          {person.instagram && (
            <a
              href={ensureHttps(person.instagram)}
              target="_blank"
              className="flex gap-4 opacity-80 hover:opacity-100 hover:bg-stone-900 rounded-xl p-2 w-full"
            >
              <InstagramIcon className="h-14" />
              <div className="flex flex-col items-start">
                <div>Instagram</div>
                <div className="text-xs text-stone-200 font-light">
                  {shortenString(person.instagram, 35)}
                </div>
              </div>
            </a>
          )}
          {person.whatsapp && (
            <a
              href={person.whatsapp}
              target="_blank"
              className="flex gap-4 opacity-80 hover:opacity-100 hover:bg-stone-900 rounded-xl p-2 w-full"
            >
              <WhatsappIcon className="h-14" />
              <div className="flex flex-col items-start">
                <div>WhatsaApp</div>
                <div className="text-xs text-stone-200 font-light">
                  {person.whatsapp}
                </div>
              </div>
            </a>
          )}
          {person.snapchat && (
            <a
              href={ensureHttps(person.snapchat)}
              target="_blank"
              className="flex gap-4 opacity-80 hover:opacity-100 hover:bg-stone-900 rounded-xl p-2 w-full"
            >
              <SnapchatIcon className="h-14" />
              <div className="flex flex-col items-start">
                <div>WhatsaApp</div>
                <div className="text-xs text-stone-200 font-light">
                  {shortenString(person.snapchat, 35)}
                </div>
              </div>
            </a>
          )}
          {person.tiktok && (
            <a
              href={ensureHttps(person.tiktok)}
              target="_blank"
              className="flex gap-4 opacity-80 hover:opacity-100 hover:bg-stone-900 rounded-xl p-2 w-full"
            >
              <TiktokIcon className="h-14" />
              <div className="flex flex-col items-start">
                <div>TikTok</div>
                <div className="text-xs text-stone-200 font-light">
                  {shortenString(person.tiktok, 35)}
                </div>
              </div>
            </a>
          )}
          {person.x && (
            <a
              href={ensureHttps(person.x)}
              target="_blank"
              className="flex gap-4 opacity-80 hover:opacity-100 hover:bg-stone-900 rounded-xl p-2 w-full"
            >
              <XIcon className="h-14" />
              <div className="flex flex-col items-start">
                <div>X</div>
                <div className="text-xs text-stone-200 font-light">
                  {shortenString(person.x, 35)}
                </div>
              </div>
            </a>
          )}
        </div>
        {/* <div className="text-stone-200 flex flex-col gap-1">
          Did you know?{" "}
          <a
            href="https://www.library.rochester.edu/spaces/studio-x"
            target="_blank"
            className="text-blue-200 text-sm hover:text-white"
          >
            You can borrow a VR headset or other cool tech at{" "}
            <span className="underline underline-offset-2">StudioX.</span>
            <strong> For free?</strong>
          </a>
        </div> */}
      </div>
    </div>
  );
};

export default NewProfileCard;
