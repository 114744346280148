import React from "react";
import { useState } from "react";
import { useAuth } from "../AuthContext";
import axios from "axios";
import Loader from "../Loader";
import Alert from "../Alert";
import { useNavigate } from "react-router-dom";


const SuggestionsList = ({
  suggestions,
  link,
  setSuggestions,
  resumePreview,
}) => {
  const [selectedSuggestions, setSelectedSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState("");
  const [alertType, setAlertType] = useState("");
  const { userLoggedIn, currentUser } = useAuth();
  const BASE_URL = process.env.REACT_APP_PUBLIC_BACKEND_URL;

  // Handle checkbox change
  const handleCheckboxChange = (suggestion) => {
    if (selectedSuggestions.includes(suggestion)) {
      setSelectedSuggestions(
        selectedSuggestions.filter((item) => item !== suggestion)
      );
    } else {
      setSelectedSuggestions([...selectedSuggestions, suggestion]);
    }
  };

  const handleSubmit = async () => {
    if (selectedSuggestions.length === 0) {
      setAlertType("error");
      setAlert("Please select at least one suggestion.");
      return;
    }

    if (!link || !currentUser) {
      setAlertType("error");
      setAlert("500. Link or user information is missing.");
      return;
    }

    setLoading(true);

    try {
      const body = {
        link,
        updates: selectedSuggestions,
        user: currentUser,
      };

      console.log("Request body for updating resume:", body);

      const response = await axios.post(`${BASE_URL}update_resume`, body);

      // Log the entire response to see what is being returned from the backend
      console.log("Response from backend:", response);

      // Check if the response status is 200 or another valid success status
      if (response.status === 200) {
        console.log("Resume successfully updated:", response.data);
        setAlertType("success");
        setAlert(<>Resume updated!</>);
      } else {
        // If the response status is not 200, show an error
        console.error("Unexpected status code:", response.status);
        setAlertType("error");
        setAlert(`500. Unexpected status code: ${response.status}`);
      }
    } catch (error) {
      // Log the full error to help with debugging
      console.error(
        "Error occurred during resume update:",
        error.response?.data || error.message || error
      );

      setAlertType("error");
      setAlert(
        <>
          501. An error occurred. Please make sure you've set your resume open
          for anyone to <span className="underline">edit</span> and try again.
        </>
      );
    } finally {
      setLoading(false); // Always set loading to false once the request completes
    }
  };

  // add https to url
  const fixLink = (link) => {
    if (link && !link.includes("http")) {
      return `https://${link}`;
    }
    return link;
  };

  return (
    <div className="w-full md:bg-stone-50 bg-opacity-25 md:p-8 py-10 rounded-lg flex flex-col items-start justify-start max-w-full">
      <div className="pb-4">Please select the changes you want applied</div>
      {alert && (
        <div className="my-2 w-full sm:flex sm:justify-start">
          <Alert
            type={alertType}
            messageBody=""
            messageHeader={alert}
            changeMessage={setAlert}
          />
        </div>
      )}
      <div
        className={`w-full grid ${
          resumePreview ? "grid-cols-1" : "lg:grid-cols-2"
        } gap-2 max-h-[50vh] overflow-y-scroll`}
      >
        {suggestions.map((suggestion, index) => (
          <div
            key={index}
            className={`flex w-full text-sm flex-row gap-4 items-center ${
              selectedSuggestions.includes(suggestion)
                ? "bg-stone-200"
                : "bg-stone-100"
            } p-4 rounded-lg mb-4`}
          >
            {/* Add a checkbox to select the suggestion */}
            <input
              type="checkbox"
              checked={selectedSuggestions.includes(suggestion)}
              onChange={() => handleCheckboxChange(suggestion)}
              className=""
            />
            <div className="flex flex-col bg-white-50 py-2 gap-2 h-full w-full rounded-lg justify-start items-start">
              <div className="font-light text-gray-400 line-through decoration-gray-600 decoration decoration-1">
                {suggestion.old_text}
              </div>
              <div className="font-light">{suggestion.new_text}</div>
            </div>
          </div>
        ))}
      </div>
      <div className="flex gap-4 py-4">
        <div
          className="text-xs text-stone-500 hover:text-stone-800 hover:cursor-pointer"
          onClick={() => setSelectedSuggestions(suggestions)}
        >
          Select all
        </div>
        <a
          className="text-xs text-stone-500 hover:text-stone-800 hover:cursor-pointer"
          href={fixLink(link)}
          target="_blank"
          rel="noreferrer"
        >
          Open resume
        </a>
      </div>
      <div className="w-full items-start justify-start">
        <div className="flex flex-row gap-4 w-full py-8">
          <button
            className="px-4 py-4 bg-gray-100 w-full  text-black rounded-lg hover:bg-gray-200"
            onClick={() => {
              setSuggestions("");
            }}
          >
            Cancel
          </button>
          <button
            className="px-4 py-4 bg-green-600 w-full text-white rounded-lg hover:bg-green-500"
            onClick={(e) => {
              handleSubmit();
              e.preventDefault();
            }}
          >
            Apply changes
          </button>
        </div>
        {loading && (
          <div className=" flex flex-col md:justify-center md:items-center p-8 gap-4">
            <Loader height={70} width={70} />
            <div className="self-center">
              <div>Hang tight, we’re doing the magic!</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SuggestionsList;
