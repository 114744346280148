import React from "react";

const GoogleDocViewer = ({ link }) => {
  return (
    <iframe
      src={`${link}?rm=minimal`}
      width="100%"
      height="780"
      frameBorder="0"
      allowFullScreen
      title="Google Doc Viewer"
      className=" rounded-lg"
    ></iframe>
  );
};

export default GoogleDocViewer;
