import { Disclosure } from "@headlessui/react";
import {
  MinusSmallIcon,
  PlusSmallIcon,
  ArrowDownIcon,
} from "@heroicons/react/24/outline";

export default function FAQ({ faqs }) {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl py-24 sm:py-20 lg:py-32">
        <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
          <div className="flex gap-2 items-center">
            <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">
              Frequently asked questions
            </h2>
            <ArrowDownIcon className="h-6 w-6 text-black opacity-50" />
          </div>

          <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
            {faqs.map((faq) => (
              <Disclosure key={faq.question} as="div" className="pt-6">
                <dt>
                  <Disclosure.Button className="group flex w-full items-start justify-between text-left text-gray-900">
                    <span className="text-base font-semibold leading-7">
                      {faq.question}
                    </span>
                    <span className="ml-6 flex h-7 items-center">
                      <PlusSmallIcon
                        aria-hidden="true"
                        className="h-6 w-6 group-data-[open]:hidden"
                      />
                      <MinusSmallIcon
                        aria-hidden="true"
                        className="h-6 w-6 [.group:not([data-open])_&]:hidden"
                      />
                    </span>
                  </Disclosure.Button>
                </dt>
                <Disclosure.Panel as="dd" className="mt-2 pr-12">
                  <p className="text-base leading-7 text-gray-600">
                    {faq.answer}
                  </p>
                </Disclosure.Panel>
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
