import React from "react";
import { motion } from "framer-motion";
import Cloud from "../Components/LogoCloud";
import Lottie from "react-lottie";
import plane from "../Assets/img/tap2.json";
import { useNavigate } from "react-router-dom";
import Card3dSection from "../Components/3dCard/Card3dSection";

const Card = () => {
  return (
    <div className="flex flex-col h-full w-full overflow-x-hidden md:pt-8 pt-4 transition-all duration-200">
      <Hero />
      <Tagline />
      {/* <Card3dSection /> */}
      <Definition />
      <Cloud />
      <Benefits />
      <Tagline2 />
    </div>
  );
};

const Hero = () => {
  const navigate = useNavigate();
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.5, // Delays the animation of each child by 0.2 seconds
        delayChildren: 0.5, // Delays the animation of each child by 0.2 seconds
      },
    },
  };

  // Define the children's animation variants
  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
    duration: 0.5,
  };
  const Cards = require("../Assets/img/cards.png");
  const HelloCard1 = require("../Assets/img/helloCard-02.png");
  const HelloCard2 = require("../Assets/img/helloCard-03.png");
  const HelloCard3 = require("../Assets/img/helloCard-04.png");
  const Arrow = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 28 14"
      strokeWidth={2.5}
      stroke="currentColor"
      className="w-4 h-4"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m8.25 4.5 7.5 7.5-7.5 7.5"
      />
    </svg>
  );

  return (
    <motion.div className="flex flex-col min-h-full lg:m-8 bg-neutral-50 lg:rounded-3xl md:py-12 px-8 lg:px-28 items-center gap-8 py-8">
      <div className="flex lg:flex-row flex-col lg:gap-16 gap-8 justify-center items-center h-full">
        <div className=" lg:w-1/2 sm:w-3/4 w-full flex items-end justify-end">
          <div className="shadow sm:p-8 py-4 rounded-3xl flex flex-col items-center">
            <motion.div
              initial={{ opacity: 0, y: 50 }} // Start 50 pixels below the final position and with zero opacity
              whileInView={{
                opacity: 1,
                y: 0,
                transition: { duration: 1, ease: "easeOut" },
              }} // Animate to full opacity and final position at y=0
              viewport={{ once: true }}
            >
              {" "}
              <img src={HelloCard2} className="-rotate-3" alt="Hello Card" />
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: -50 }} // Start 50 pixels below the final position and with zero opacity
              whileInView={{
                opacity: 1,
                y: 0,
                transition: { duration: 1, ease: "easeOut", delay: 3 },
              }} // Animate to full opacity and final position at y=0
              viewport={{ once: true }}
            >
              <p className="p-8 font-light text-xs md:text-xl text-green-800 text-center">
                {" "}
                Share your contact details or portfolio with just a{" "}
                <strong className="font-bold">Tap</strong>
              </p>
            </motion.div>
          </div>
        </div>
        <motion.ul
          variants={containerVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          className="flex lg:w-1/2 flex-col justify-center items-center flex-grow-0 gap-8"
        >
          {" "}
          <div className="sm:text-5xl text-3xl  text-left flex flex-col font-medium gap-2">
            {" "}
            <motion.li
              variants={itemVariants}
              transition={{ duration: 1, ease: "anticipate" }}
              className="bg-pink-100 sm:rotate-0 -rotate-6 py-2 px-6 rounded-xl text-pink-600 duration-400 lg:self-start self-center"
            >
              We met.
            </motion.li>
            <motion.li
              variants={itemVariants}
              transition={{ duration: 1, ease: "anticipate" }}
              className="bg-blue-100 py-2 px-6 sm:rotate-0 rotate-3 rounded-xl text-blue-800 duration-400 lg:self-start self-center"
            >
              We tapped.
            </motion.li>
            <motion.li
              variants={itemVariants}
              transition={{ duration: 1, ease: "anticipate" }}
              className="bg-green-100 py-2 px-4 rounded-xl sm:rotate-0 -rotate-4 text-green-800 duration-400 lg:self-start self-center"
            >
              We connected.
            </motion.li>
          </div>
        </motion.ul>
      </div>
      <div className="text-blue-600 hover:border-b  border-blue-700 hover:text-blue-700 hover:font-bold  hover:cursor-pointer gap-2 mt-8 flex flex-row items-baseline justify-center transition-all">
        <a as="a" href="/#card">
          Learn more
        </a>
        <div className="font-bold ">{Arrow}</div>
      </div>
    </motion.div>
  );
};

const Tagline = () => {
  return (
    <motion.div
      id="card"
      initial={{ opacity: 0, y: 60 }} // Start 50 pixels below the final position and with zero opacity
      whileInView={{
        opacity: 1,
        y: 0,
        transition: { duration: 1, ease: "easeOut" },
      }} // Animate to full opacity and final position at y=0
      viewport={{ once: true, amount: 0.5 }}
      exit="hidden"
      className="lg:p-28 mt-4  min-h-auto  lg:text-6xl text-3xl font-bold text-blue-900 flex flex-col items-center justify-center gap-4"
    >
      <div className="flex justify-center w-full">
        <img
          src={require("../Assets/img/standout.png")}
          alt="faces"
          className="h-96 md:hidden object-contain object-center rounded-3xl w-full "
        />
        <img
          src={require("../Assets/img/standout2.png")}
          alt="faces"
          className="hidden md:block object-contain object-center rounded-3xl w-2/3 "
        />
      </div>
      <motion.div
        id="2"
        initial={{ opacity: 0, y: 60 }} // Start 50 pixels below the final position and with zero opacity
        whileInView={{
          opacity: 1,
          y: 0,
          transition: { duration: 1, ease: "easeOut" },
        }} // Animate to full opacity and final position at y=0
        viewport={{ once: true, amount: 1 }}
        exit="hidden"
      >
        <div className="p-8 text-center text-3xl lg:text-6xl">
          The{" "}
          <span className="bg-blue-100 px-2 text-blue-800 border-b-2  border-blue-200  transition-all">
            business card
          </span>{" "}
          that sets you apart.
        </div>
      </motion.div>
    </motion.div>
  );
};

const Definition = () => {
  const lottieOptions = {
    loop: true,
    speed: 0.5,
    autoplay: true,
    animationData: plane,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.15, // Delays the animation of each child by 0.2 seconds
        delayChildren: 0.35, // Delays the animation of each child by 0.2 seconds
        transition: { duration: 1, ease: "circInOut" },
      },
    },
  };

  // Define the children's animation variants
  const itemVariants = {
    hidden: { opacity: 0, x: 20 },
    visible: { opacity: 1, x: 0 },
    transition: { duration: 2, ease: "circInOut" },
  };

  const words = [
    "visionaries",
    "misfits",
    "students",
    "professionals",
    "stylish",
    "bold",
    "you",
  ];
  return (
    <motion.div
      id="3"
      initial={{ opacity: 0, y: 50 }}
      whileInView={{
        opacity: 1,
        y: 0,
        transition: { duration: 0.5, ease: "circInOut" },
      }}
      viewport={{ once: true, amount: 0.5 }}
      exit="hidden"
      className="p-4 min-h-screen relative bg-neutral-50 flex flex-col lg:flex-row items-center justify-center gap-8"
    >
      <div className="absolute top-3 md:h-20 md:w-20 h-10 w-10 right-5 md:right-40 lg:top-40 lg:right-96">
        <Lottie options={lottieOptions} className="" />
      </div>

      {/* Text Section */}
      <div className="text-xl leading-loose md:text-2xl order-2 lg:order-1 md:w-2/3 lg:w-1/3 p-8 px-3 sm:px-8 lg:px-2 md:leading-loose text-black flex flex-col  gap-8 ">
        <div>
          <div className="font-bold text-4xl">Penpal Card</div>
          <p>/ˈpɛnpæl kɑːrd/</p>
          <p className="text-black px-1 text-lg font-sans">noun</p>
        </div>
        <div>
          Penpal Card is the cool, new way for you to swap contact details or
          portfolios.
          <br />
          Just tap your card on their phone, and all your info pops up on their
          device.
        </div>
        {/* List Section */}
        <motion.ul
          className="w-full flex flex-wrap gap-2 text-lg"
          variants={containerVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
        >
          <div className="text-green-600 text-lg italic">Created for:</div>
          {words.map((word, index) => (
            <motion.li
              key={index}
              className="rounded-full outline-1 border border-gray-300 px-4"
              variants={itemVariants}
              transition={{ duration: 1, ease: "circIn" }}
            >
              {word}
            </motion.li>
          ))}
        </motion.ul>
      </div>

      {/* Image Section */}
      <div className="flex w-full md:order-1 lg:order-2 justify-center md:justify-center lg:w-1/2">
        <img
          src={require("../Assets/img/haldy.png")}
          alt="phone"
          className="w-96 lg:w-full lg:p-20 object-contain object-center rounded-3xl"
        />
      </div>
    </motion.div>
  );
};

const Benefits = () => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.5, // Delays the animation of each child by 0.2 seconds
        // Delays the animation of each child by 0.2 seconds
      },
    },
  };

  // Define the children's animation variants
  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
    duration: 1,
  };

  const benefits = [
    {
      title: "It's eco friendly",
      desc: "Who walks around with piles of paper business cards anymore?",
      icon: require("../Assets/img/benefits/benefits-01.png"),
    },
    {
      title: "First impressions matter",
      desc: "You never get a second chance to make a first impression. Make it count.",
      icon: require("../Assets/img/benefits/benefits-02.png"),
    },
    {
      title: "Swap info smoothly",
      desc: "Share your contact details or portfolio with just a tap.",
      icon: require("../Assets/img/benefits/benefits-04.png"),
    },
    {
      title: "Sorted contacts",
      desc: "All your new connections are neatly organized with tags to help you remember where and when you met them.",
      icon: require("../Assets/img/benefits/benefits-03.png"),
    },
  ];
  return (
    <motion.div
      id="4"
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true, amount: 0.4 }}
      className="min-h-screen bg-black w-full flex flex-col items-center justify-center gap-12 p-12 py-16"
    >
      <div className="h-full flex flex-col justify-center items-start gap-12">
        <div className="text-white text-2xl text-start justify-start items-start px-4">
          Here is why everyone is switching to Penpal Card
        </div>
        <motion.ul
          className="grid grid-cols-1 md:grid-cols-2 gap-12 md:gap-1 justify-items-stretch"
          variants={containerVariants}
          initial="hidden"
          whileInView="visible"
        >
          {benefits.map((benefit, index) => (
            <motion.li
              className="flex flex-col min-w-0 group-hover group md:min-w-[300px] md:max-w-[500px] border-b border-opacity-20  border-white h-auto md:min-h-72 justify-between  items-start p-4 gap-3"
              key={index}
              initial="hidden"
              whileInView="visible"
              variants={itemVariants}
              transition={{ duration: 1, ease: "anticipate" }}
              viewport={{ once: true, amount: 0.5 }}
            >
              <div>
                <img
                  src={benefit.icon}
                  className="h-32 group-hover:scale-110 transition-all"
                />
              </div>
              <div className="bg-gradient-to-br from-blue-400 to-blue-800 bg-clip-text text-transparent lg:text-2xl font-bold justify-center items-start flex flex-col gap-2">
                {benefit.title}
              </div>
              <div className="text-white text-lg sm:w-2/3 lg:w-full text-start ">
                {benefit.desc}
              </div>
            </motion.li>
          ))}
        </motion.ul>
      </div>
    </motion.div>
  );
};

const Tagline2 = () => {
  const navigate = useNavigate();
  return (
    <motion.div
      id="5"
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      className="h-auto w-full flex flex-col items-center justify-center gap-8 p-8 mt-8"
    >
      <div className="flex flex-col gap-8 md:gap-12">
        <div className="bg-gradient-to-br from-blue-400 h-full to-blue-800 bg-clip-text text-transparent lg:text-8xl md:text-6xl text-4xl font-bold justify-center items-start w-full flex flex-col gap-8 pb-2">
          Stand out.
          <br /> In style.
          <br />
        </div>
        <div
          className="font-normal flex-grow-0 text-white text-sm md:text-lg lg:text-lg py-2 px-4 bg-black hover:bg-blue-800 hover:cursor-pointer rounded-xl self-start transition-all"
          onClick={() =>
            (window.location.href = "https://www.amazon.com/dp/B0DCLPXDDY")
          }
        >
          Get yours today
        </div>
      </div>
    </motion.div>
  );
};

export default Card;
