import React, { useState, useEffect } from "react";
import { Navigate, Link, useParams, useLocation } from "react-router-dom";
import Loader from "../../Components/Loader.js";
import axios from "axios";
import Alert from "../../Components/Alert.js";
import {
  doSignInWithEmailAndPassword,
  doSignInWithGoogle,
} from "../../firebase/auth.js";
import { useAuth } from "../../Components/AuthContext.js";
import ResumeFaq from "./ResumeFaq.js";
import ResumeOnboarding from "../../Components/ResumeTailor/ResumeOnboarding.js";
import SuggestionsList from "../../Components/ResumeTailor/SuggestionsList.js";
import GreetingComponent from "../../Components/ResumeTailor/Greeting.js";
import GoogleDocViewer from "../../Components/ResumeTailor/GoogleDocViewer.js";
import {
  XCircleIcon,
  ArrowUpRightIcon,
  EyeIcon,
  EyeSlashIcon,
} from "@heroicons/react/24/outline";
import { ArrowPathIcon } from "@heroicons/react/20/solid";

const BASE_URL = process.env.REACT_APP_PUBLIC_BACKEND_URL;
const ResumeTailor = () => {
  const { docLinkCheck } = useParams();
  const [link, setLink] = useState("");
  const [jobDescription, setJobDescription] = useState("");
  const [makeCopy, setMakeCopy] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState("");
  const [alertType, setAlertType] = useState("");
  const [suggestions, setSuggestions] = useState();
  const [onboarding, setOnboarding] = useState(false);
  const BASE_URL = process.env.REACT_APP_PUBLIC_BACKEND_URL;
  const { userLoggedIn, currentUser } = useAuth();
  const [resumePreview, setResumePreview] = useState(false);
  //MONGO USER
  const [user, setUser] = useState();

  useEffect(() => {
    console.log(location.pathname.split("/"));
    if (location.pathname.split("/").length > 5) {
      const docLink = location.pathname.replace("/resume/", "");
      setLink(docLink);
    }
  }, []);

  const tailorResume = async (e) => {
    e.preventDefault();

    // Check if the job description is provided
    if (jobDescription.length === 0) {
      setAlertType("error");
      setAlert("Please fill in all required fields.");
      return;
    }

    setLoading(true);

    try {
      let googleUser;
      // Check if the user is already signed in
      if (!userLoggedIn) {
        googleUser = await doSignInWithGoogle().catch((err) => {
          console.error(err);
          setAlertType("error");
          setAlert("An error occurred while signing in. Please try again.");
          return null; // Return null to indicate failure
        });

        // Stop the function if sign-in failed
        if (!googleUser) return;
      }

      // Check if the user already exists in MongoDB
      const checkUserResponse = await axios.post(
        `${BASE_URL}get_resume_tailor_user`,
        {
          email: googleUser?.email || currentUser.email, // Use googleUser if available, otherwise use currentUser
        }
      );

      const userExists = checkUserResponse.data.userExists;
      let user = googleUser || currentUser;

      if (!userExists) {
        // If user doesn't exist, onboard them
        user = {
          displayName: googleUser?.displayName || currentUser.displayName,
          email: googleUser?.email || currentUser.email,
          googleId: googleUser?.uid || currentUser.uid,
          photoURL: googleUser?.photoURL || currentUser.photoURL,
        };

        // Insert user in MongoDB
        const response = await axios.post(
          `${BASE_URL}save_resume_tailoring_user`,
          { user }
        );

        setUser(response.data.user);

        // Show onboarding if the user requires it
        if (!userExists || user.tailoredResumes < 1) {
          setLoading(false);
          setOnboarding(true);

          return;
        }
      }

      // Proceed only if the currentUser exists
      if (!user) {
        setAlertType("error");
        setAlert("500. An error occurred. Please try again.");
        return;
      }

      // If user exists and onboarding is complete, proceed with tailoring

      setLoading(true);
      const body = {
        link,
        jobDescription,
        user,
        makeCopy,
      };

      const response = await axios.post(
        `${BASE_URL}get_resume_suggestions`,
        body
      );
      setSuggestions(response.data.changes);
      setLink(response.data.link);
      setAlertType("success");
      setAlert("Suggestions received!");
    } catch (error) {
      console.error(error);
      setAlertType("error");
      setAlert(
        <>
          An error occurred. Please make sure your resume is in{" "}
          <span
            className="underline hover:opacity-50 cursor-pointer"
            onClick={() => setOnboarding(true)}
          >
            this format
          </span>{" "}
          and set to 'Anyone with link can edit
        </>
      );
    } finally {
      setLoading(false); // Stop loading in any case (success or failure)
    }
  };

  //fix url link
  const fixLink = (link) => {
    if (link && !link.includes("http")) {
      return `https://${link}`;
    }
    return link;
  };

  //show resume preview
  const showResumePreview = () => {
    setResumePreview(!resumePreview);
  };

  if (loading) {
    return (
      <div className="w-full h-full flex flex-col pt-72 md:justify-center md:items-center p-8 gap-4">
        <Loader height={100} width={100} />
        <div className="self-center">
          <div>Hang tight, we’re doing the magic!</div>
        </div>
      </div>
    );
  } else if (suggestions) {
    return (
      <div className="w-full h-full flex justify-center items-center md:pt-12">
        <div
          className={` ${
            !resumePreview ? "md:bg-stone-50 md:w-2/3" : "md:w-full "
          } relative rounded-lg h-full flex flex-col  md:justify-center md:items-center p-8 gap-4`}
        >
          <div className="flex gap-2 absolute top-10 md:right-16 right-8">
            <ArrowPathIcon
              className="h-4 text-gray-400 hidden lg:block hover:text-black cursor-pointer"
              onClick={(e) => {
                tailorResume(e);
              }}
            />
            {resumePreview ? (
              <EyeIcon
                className="h-4 text-gray-400 hidden lg:block hover:text-black cursor-pointer"
                onClick={() => showResumePreview()}
              />
            ) : (
              <EyeSlashIcon
                className="h-4 text-gray-400 hidden lg:block hover:text-black cursor-pointer"
                onClick={() => showResumePreview()}
              />
            )}
            <ArrowUpRightIcon
              className="h-4 text-gray-400 hover:text-black cursor-pointer"
              onClick={() => window.open(fixLink(link), "_blank")}
            />
          </div>
          <div className="text-3xl font-extralight self-center pb-8">
            Almost done...
          </div>
          <div className="flex gap-4 w-full items-start justify-center lg:px-8">
            <div className={`w-full ${resumePreview ? "lg:w-1/2" : "w-full"}`}>
              <SuggestionsList
                suggestions={suggestions}
                link={link}
                resumePreview={resumePreview}
                setSuggestions={setSuggestions}
              />
            </div>
            {resumePreview && (
              <div className="lg:w-1/2 hidden lg:block">
                <GoogleDocViewer link={fixLink(link)} />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="w-full h-full relative flex flex-col  md:justify-center md:items-center py-8 gap-4">
        <GreetingComponent currentUser={currentUser} />
        {onboarding && (
          <ResumeOnboarding
            setOnboarding={setOnboarding}
            tailorResume={tailorResume}
          />
        )}
        <div className="text-3xl font-extralight self-center pb-8">
          Let’s Polish Your Resume
        </div>
        {alert && (
          <div className="w-full sm:flex sm:justify-center">
            <Alert
              type={alertType}
              messageBody=""
              messageHeader={alert}
              changeMessage={setAlert}
            />
          </div>
        )}
        <form
          className="flex flex-col bg-white-50  gap-8 h-full w-full  max-w-[800px] rounded-lg py-8 px-4 justify-start"
          onSubmit={tailorResume}
        >
          <div className="relative w-full">
            <label
              htmlFor="link"
              className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
            >
              Resume Link
            </label>
            <input
              id="link"
              name="link"
              type="text"
              placeholder="Drop your Google Doc link"
              value={link}
              onChange={(e) => setLink(e.target.value)}
              className="block w-full outline-none rounded-md border-.5  py-4 font-light  px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
            />
          </div>
          <div className="relative w-full">
            <label
              htmlFor="description"
              className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
            >
              Add Job Description
            </label>
            <textarea
              id="job_description"
              name="job_description"
              type="text"
              value={jobDescription}
              onChange={(e) => setJobDescription(e.target.value)}
              placeholder={`- Coding experience with C# or .NET is a plus \n- Attention to detail and organizational skill \n- Interest in learning and working with new technologies 
                        `}
              className="block w-full min-h-[300px] rounded-md border-.5  py-4 px-3 text-gray-900 shadow-sm font-light ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset outline-none focus:ring-sky-600 sm:text-sm sm:leading-6"
            />
          </div>
          <fieldset>
            <legend className="sr-only">Type</legend>
            <div className="space-y-2">
              <div className="relative flex items-center">
                <div className="flex h-6 items-center">
                  <input
                    id="makeCopy"
                    name="makeCopy"
                    type="checkbox"
                    checked={makeCopy}
                    onChange={(e) => setMakeCopy(e.target.checked)}
                    aria-describedby="candidates-description"
                    className="h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-600"
                  />
                </div>
                <div className="ml-3 text-sm leading-6">
                  <label htmlFor="candidates" className=" text-gray-900">
                    Make a copy of your document before editing
                  </label>{" "}
                  <div className="text-gray-400 text-xs">
                    Your original document stays unchanged
                  </div>
                </div>
              </div>
            </div>
          </fieldset>

          <button
            type="submit"
            className="px-2 py-4 bg-sky-700 text-white rounded-lg hover:bg-sky-600"
          >
            Match keywords
          </button>

          <ResumeFaq />
        </form>
      </div>
    );
  }
};

export default ResumeTailor;
