import React from "react";
import { useState } from "react";
import Loader from "../Components/Loader";
import axios from "axios";
import Alert from "../Components/Alert";

const OpportunitiesSignUp = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [newGrad, setNewGrad] = useState(false);
  const [internship, setInternship] = useState(false);
  const [loading, setloading] = useState(false);
  const [alert, setAlert] = useState("");
  const [alertType, setAlertType] = useState("");
  const BASE_URL = process.env.REACT_APP_PUBLIC_BACKEND_URL;

  const submitUser = async (e) => {
    e.preventDefault();

    if (email.length === 0 || name.length === 0) {
      setAlertType("error");
      setAlert("Please fill in all required fields.");
      return;
    }

    setloading(true);

    try {
      const body = {
        name,
        email,
        newGrad,
        internship,
      };

      await axios.post(`${BASE_URL}save_opportunity`, body);
      setAlertType("success");
      setAlert(
        <>
          Success! <br></br> Check out{" "}
          <a
            href="/linkedin"
            target="_blank"
            rel="noopener noreferrer"
            className="underline"
          >
            RoastMyLinkedIn
          </a>{" "}
        </>
      );
    } catch (error) {
      console.error(error);
      setAlertType("error");
      setAlert("An error occurred. Please try again.");
    }
    setloading(false);
  };

  return (
    <div className="w-full h-full flex flex-col  md:justify-center md:items-center p-8 gap-4">
      <div className="text-3xl font-extralight pb-8">
        New Grad & Summer 2025 Opportunites
      </div>
      <form
        className="flex flex-col  gap-8 h-full w-full  max-w-[400px] border rounded-lg py-8 px-4 justify-start"
        onSubmit={submitUser}
      >
        <div className="text-3xl font-bold">
          Join
          <div className="text-sm font-normal opacity-50 pt-2">
            Leave your email to get personalized job recommendations
          </div>
        </div>
        <div className="relative w-full">
          <label
            htmlFor="name"
            className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
          >
            Name
          </label>
          <input
            id="name"
            name="name"
            type="text"
            placeholder="Jane"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="block w-full outline-none rounded-md border-0 py-4 font-light  px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
          />
        </div>
        <div className="relative w-full">
          <label
            htmlFor="email"
            className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
          >
            Email
          </label>
          <input
            id="email"
            name="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="ajames@u.rochester.edu"
            className="block w-full rounded-md border-0 py-4 px-3 text-gray-900 shadow-sm font-light ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset outline-none focus:ring-sky-600 sm:text-sm sm:leading-6"
          />
        </div>
        <fieldset>
          <legend className="sr-only">Type</legend>
          <div className="space-y-2">
            <div className="relative flex items-start">
              <div className="flex h-6 items-center">
                <input
                  id="newGrad"
                  name="newGrad"
                  type="checkbox"
                  checked={newGrad}
                  onChange={(e) => setNewGrad(e.target.checked)}
                  aria-describedby="candidates-description"
                  className="h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-600"
                />
              </div>
              <div className="ml-3 text-sm leading-6">
                <label htmlFor="candidates" className=" text-gray-900">
                  New grad opportunities
                </label>{" "}
              </div>
            </div>
            <div className="relative flex items-start">
              <div className="flex h-6 items-center">
                <input
                  id="internship"
                  name="internship"
                  type="checkbox"
                  checked={internship}
                  onChange={(e) => setInternship(e.target.checked)}
                  aria-describedby="candidates-description"
                  className="h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-600"
                />
              </div>
              <div className="ml-3 text-sm leading-6">
                <label htmlFor="candidates" className=" text-gray-900">
                  Internship opportunities
                </label>{" "}
              </div>
            </div>
          </div>
        </fieldset>

        <button
          type="submit"
          className="px-2 py-4 bg-sky-700 text-white rounded-lg hover:bg-sky-600"
        >
          Submit
        </button>
        {loading && (
          <div className="mt-6 max-w-xl lg:mt-0 xl:row-start-1">
            <Loader height={70} width={70} />
          </div>
        )}
        {alert && (
          <div className="my-4 w-full sm:flex sm:justify-center">
            <Alert
              type={alertType}
              messageBody=""
              messageHeader={alert}
              changeMessage={setAlert}
            />
          </div>
        )}
      </form>
    </div>
  );
};

export default OpportunitiesSignUp;
